export default function Loading() {
  const outer = document.createElement('div');
  outer.classList.add('mainLoading');
  outer.classList.add('mainLoading-grow');
  for (let i = 1; i <= 3; i++) {
    const inner = document.createElement('div');
    inner.classList.add('mainLoadingDot');
    inner.classList.add(`dot-${i}`);
    outer.appendChild(inner);
  }
  return outer;
}