import PortalIdParser from 'PortalIdParser';
import Raven from 'raven-js';
import HomeDAO from '../data/HomeDAO';
import Loading from '../templates/Loading';
import redirect from '../utils/redirect';
import { error, login } from '../utils/urls';
class RootEntry {
  constructor() {
    this.page = null;
  }
  start() {
    PortalIdParser.getPortalIdFromQueryParam();
    this.page = document.getElementsByClassName('page')[0];
    this.renderLoading();
    HomeDAO.getUserPortalId(({
      portalId
    }) => this.redirect(portalId), () => this.redirect());
  }
  redirect(portalId = null) {
    HomeDAO.get(portalId, ({
      uri
    }) => redirect(uri), errorResp => this.homeFailure(errorResp));
  }
  renderLoading() {
    if (this.page) this.page.appendChild(Loading());
  }
  homeFailure(errorResp) {
    if (errorResp.status === 400 || errorResp.status === 401 || errorResp.status === 403) {
      redirect(login(PortalIdParser.getPortalIdFromQueryParam()));
    } else {
      Raven.captureMessage('Home api error', {
        extra: {
          readyState: errorResp.readyState,
          responseText: errorResp.responseText,
          responseURL: errorResp.responseURL,
          status: errorResp.status,
          statusText: errorResp.statusText
        }
      });
      redirect(error());
    }
  }
}
new RootEntry().start();